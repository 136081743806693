

.card-shadow{
    box-shadow: 0px 0px 15px #a3a3a34a;
}

.form-group-divisor{
    
    border-left: 1px solid #cccc;
    padding-left: 12px;
}