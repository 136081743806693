.story {
  width: 100%;
  max-width: 450px;
  background: #bdbdbd;

  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  .story-content {
    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
    .controls {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      .preview {
        flex: 1;
        height: 100%;
      }
    }

    .timer {
      position: absolute;
      bottom: 16px;
      left: 5%;
      width: 90%;
      height: 4px;
      display: flex;
      .timer-item {
        flex: 1;
        margin-left: 4px;
        margin-right: 4px;
        height: 4px;
        background: #f9f9f980;
        border-radius: 5px;
        overflow: hidden;

        .timer-item-complete {
          background: white;
          height: 100%;
        }
      }
    }
  }
}

.caption {
  color: #212121;
  margin-top: 12px;
  font-size: 14px;
}


.story-title{
    
    color: #7A7A7A;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 24px;

}