.custom-table {
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px #00000014;
  th {
    color: #5a5959;
    font-weight: bold;
    font-size: 14px;
    padding: 12px 16px;
    border-bottom: 2px solid #e0e0e0; 
    white-space: nowrap;

    &:not(last-child) {
      border-right: 1px solid #e0e0e0;
    }
  }
  td {
    color: #201E1E;
    font-size: 14px;
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
    width:1%;
    white-space:nowrap;

    &:not(last-child) {
      border-right: 1px solid #e0e0e0;
    }
  }
}
