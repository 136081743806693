.pallet {
  padding-left: 3px;
  .pallet-menu {
    display: flex;
    flex-direction: row;
    overflow: auto;

    padding-left: 5px;
    padding-top: 3px;
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }

    .pallet-item {
      cursor: pointer;
      padding: 8px;

      &.active {
        background: #fff;
        border-radius: 2px 2px 0 0;
        box-shadow: 0px 0px 4px #00000021;
        margin-left: 0px;
      }
      .pallet-color {
        width: 32px;
        height: 32px;
        background: red;
        border-radius: 2px;
      }
    }
  }
  .pallet-card {
    padding: 12px;
    background: #fff;

    box-shadow: 0px 0px 10px #00000014 !important;
  }
}
.pallet-title {
  color: #212121;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.pallet-text {
  color: #7a7a7a;
  margin-top: 16px;

  b {
    color: #7a7a7a;
  }
}
